<template>
  <div :id="idTable + '_container '">
    <div :id="idTable + '_scrollDuplicate'" class="table_scroll_duplicate">
      <div :id="idTable + '_dummy'"></div>
    </div>
    <v-data-table
      :id="idTable"
      :loading="loadingTable"
      :headers="headers"
      :options.sync="pagination"
      :items="items"
      :page="pagination.page"
      :server-items-length="totalItems"
      :footer-props="{
        disablePagination: items.length === 0 || loadingTable,
        itemsPerPageOptions:
          totalItems < 50
            ? [
                { value: 50, text: '50' },
                { value: 100, text: '100', disabled: true },
                { value: 250, text: '250', disabled: true },
              ]
            : totalItems < 100
            ? [
                { value: 50, text: '50' },
                { value: 100, text: '100' },
                { value: 250, text: '250', disabled: true },
              ]
            : [
                { value: 50, text: '50' },
                { value: 100, text: '100' },
                { value: 250, text: '250' },
              ],
        disableItemsPerPage: items.length === 0 || loadingTable,
        itemsPerPageText: `${tableTitle} por página: `,
        expandIcon: 'mdi-dots-horizontal',
      }"
      :no-data-text="`Não há registros de ${tableTitle} a serem exibidos.`"
      @current-items="onTableInput"
    >
      <template v-slot:footer.page-text="items">
        {{ loadingTable ? 0 : items.pageStart }} - {{ loadingTable ? 0 : items.pageStop }} de
        {{ loadingTable ? 0 : items.itemsLength }} {{ tableTitle }}
      </template>
      <template v-slot:body v-if="loadingTable">
        <tr>
          <td v-for="loadItem in headers">
            <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <div id="noDataText" class="d-flex justify-center" :style="getTableWidth">
          <p>Não há registros de {{ tableTitle }} a serem exibidos.</p>
        </div>
      </template>

      <template v-slot:item.id_column="{ item }">
        {{ item.id_column }}
      </template>

      <template v-slot:item.nomeEmpresa="{ item }">
        <span class="afastados-nome-empresa">
          <AGTooltip :tooltip-text="item.nomeEmpresa">
            <template slot:tooltipArea>
              <div class="text-no-wrap text-truncate">
                {{ item.nomeEmpresa }}
              </div>
            </template>
          </AGTooltip>
          <div class="afastados-cnpj">
            {{ item.cnpj | cnpj }}
          </div>
        </span>
      </template>

      <template v-slot:item.motivoAfastamento="{ item }">
        <AGTooltip :tooltip-text="item.motivoAfastamento">
          <template slot:tooltipArea>
            <div class="afastados-motivo-afastamento">
              <div class="text-no-wrap text-truncate">
                {{ item.motivoAfastamento }}
              </div>
            </div>
          </template>
        </AGTooltip>
      </template>

      <template v-slot:item.categoria="{ item }">
        <AGTooltip :tooltip-text="item.categoria">
          <template slot:tooltipArea>
            <div class="afastados-categoria">
              <div class="text-no-wrap text-truncate">
                {{ item.categoria }}
              </div>
            </div>
          </template>
        </AGTooltip>
      </template>

      <template v-slot:item.tpRegPrev="{ item }">
        <AGTooltip :tooltip-text="item.tpRegPrev">
          <template slot:tooltipArea>
            <div class="afastados-tp-reg-prev">
              <div class="text-no-wrap text-truncate">
                {{ item.tpRegPrev }}
              </div>
            </div>
          </template>
        </AGTooltip>
      </template>

      <template v-slot:item.funcao="{ item }">
        <div class="text-no-wrap">
          {{ item.funcao }}
        </div>
      </template>

      <template v-slot:item.situacao="{ item }">
        <span v-if="item.situacao === 'active'" class="situacao-ativo">Ativo</span>
        <span v-if="item.situacao === 'inactive'" class="situacao-inativo">Inativo</span>
        <span v-if="item.situacao === 'retired'" class="situacao-afastado">Afastado</span>
        <span v-if="item.situacao === 'vacation'" class="situacao-ferias">Afastado</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { uniqueId } from 'lodash';
import TableMixin from "@/mixins/Table.js"

export default {
  name: 'TableAfastadosEInativos',

  components: {
    AGTooltip: () => import('../../commons/AGTooltip.vue'),
  },

  mixins: [TableMixin],

  props: {
    idTable: {
      type: String,
      default: uniqueId('table_'),
    },

    loadingTable: {
      type: Boolean,
      default: false,
    },

    headers: {
      type: Array,
      required: true,
    },

    paginationFilter: {
      type: Object,
      default: () => {
        return {
          itemsPerPage: 50,
          page: 1,
          sortBy: [],
        };
      },
    },

    items: {
      type: Array,
      default: () => {
        return [];
      },
    },

    totalItems: {
      type: Number,
      required: false,
    },

    footerProps: {
      type: Object,
      default: () => {
        return {};
      },
    },

    tableTitle: {
      type: String,
      required: true,
    },
  },

  computed: {
    pagination: {
      get: function () {
        return this.paginationFilter;
      },

      set: function (value) {
        this.$emit('setPagination', value);
      },
    },
  }
};
</script>

<style lang="scss" scoped>
@import './src/design/components/table.scss';
@import './src/design/components/tooltip.scss';

.afastados-nome-empresa {
  div {
    max-width: 380px;
    min-width: 320px;
  }
  .afastados-cnpj {
    font-size: $font-size-xxs;
  }
}

.afastados-motivo-afastamento {
  width: 330px;
}

.afastados-categoria,
.afastados-tp-reg-prev {
  width: 380px;
}

.situacao-ativo {
  color: $feedback-color-success-medium;
}
.situacao-afastado {
  color: $feedback-color-attention-medium;
}
.situacao-inativo {
  color: $feedback-color-error-medium;
}
.situacao-ferias {
  color: $feedback-color-success-medium;
}
</style>

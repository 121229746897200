var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.idTable + "_container " } },
    [
      _c(
        "div",
        {
          staticClass: "table_scroll_duplicate",
          attrs: { id: _vm.idTable + "_scrollDuplicate" },
        },
        [_c("div", { attrs: { id: _vm.idTable + "_dummy" } })]
      ),
      _c("v-data-table", {
        attrs: {
          id: _vm.idTable,
          loading: _vm.loadingTable,
          headers: _vm.headers,
          options: _vm.pagination,
          items: _vm.items,
          page: _vm.pagination.page,
          "server-items-length": _vm.totalItems,
          "footer-props": {
            disablePagination: _vm.items.length === 0 || _vm.loadingTable,
            itemsPerPageOptions:
              _vm.totalItems < 50
                ? [
                    { value: 50, text: "50" },
                    { value: 100, text: "100", disabled: true },
                    { value: 250, text: "250", disabled: true },
                  ]
                : _vm.totalItems < 100
                ? [
                    { value: 50, text: "50" },
                    { value: 100, text: "100" },
                    { value: 250, text: "250", disabled: true },
                  ]
                : [
                    { value: 50, text: "50" },
                    { value: 100, text: "100" },
                    { value: 250, text: "250" },
                  ],
            disableItemsPerPage: _vm.items.length === 0 || _vm.loadingTable,
            itemsPerPageText: `${_vm.tableTitle} por página: `,
            expandIcon: "mdi-dots-horizontal",
          },
          "no-data-text": `Não há registros de ${_vm.tableTitle} a serem exibidos.`,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
          "current-items": _vm.onTableInput,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "footer.page-text",
              fn: function (items) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.loadingTable ? 0 : items.pageStart) +
                      " - " +
                      _vm._s(_vm.loadingTable ? 0 : items.pageStop) +
                      " de " +
                      _vm._s(_vm.loadingTable ? 0 : items.itemsLength) +
                      " " +
                      _vm._s(_vm.tableTitle) +
                      " "
                  ),
                ]
              },
            },
            _vm.loadingTable
              ? {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "tr",
                        _vm._l(_vm.headers, function (loadItem) {
                          return _c(
                            "td",
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "loaderCss",
                                attrs: { "max-width": loadItem.width },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "no-data",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center",
                      style: _vm.getTableWidth,
                      attrs: { id: "noDataText" },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "Não há registros de " +
                            _vm._s(_vm.tableTitle) +
                            " a serem exibidos."
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "item.id_column",
              fn: function ({ item }) {
                return [_vm._v(" " + _vm._s(item.id_column) + " ")]
              },
            },
            {
              key: "item.nomeEmpresa",
              fn: function ({ item }) {
                return [
                  _c(
                    "span",
                    { staticClass: "afastados-nome-empresa" },
                    [
                      _c(
                        "AGTooltip",
                        { attrs: { "tooltip-text": item.nomeEmpresa } },
                        [
                          [
                            _c(
                              "div",
                              { staticClass: "text-no-wrap text-truncate" },
                              [_vm._v(" " + _vm._s(item.nomeEmpresa) + " ")]
                            ),
                          ],
                        ],
                        2
                      ),
                      _c("div", { staticClass: "afastados-cnpj" }, [
                        _vm._v(" " + _vm._s(_vm._f("cnpj")(item.cnpj)) + " "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "item.motivoAfastamento",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.motivoAfastamento } },
                    [
                      [
                        _c(
                          "div",
                          { staticClass: "afastados-motivo-afastamento" },
                          [
                            _c(
                              "div",
                              { staticClass: "text-no-wrap text-truncate" },
                              [
                                _vm._v(
                                  " " + _vm._s(item.motivoAfastamento) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.categoria",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.categoria } },
                    [
                      [
                        _c("div", { staticClass: "afastados-categoria" }, [
                          _c(
                            "div",
                            { staticClass: "text-no-wrap text-truncate" },
                            [_vm._v(" " + _vm._s(item.categoria) + " ")]
                          ),
                        ]),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.tpRegPrev",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.tpRegPrev } },
                    [
                      [
                        _c("div", { staticClass: "afastados-tp-reg-prev" }, [
                          _c(
                            "div",
                            { staticClass: "text-no-wrap text-truncate" },
                            [_vm._v(" " + _vm._s(item.tpRegPrev) + " ")]
                          ),
                        ]),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.funcao",
              fn: function ({ item }) {
                return [
                  _c("div", { staticClass: "text-no-wrap" }, [
                    _vm._v(" " + _vm._s(item.funcao) + " "),
                  ]),
                ]
              },
            },
            {
              key: "item.situacao",
              fn: function ({ item }) {
                return [
                  item.situacao === "active"
                    ? _c("span", { staticClass: "situacao-ativo" }, [
                        _vm._v("Ativo"),
                      ])
                    : _vm._e(),
                  item.situacao === "inactive"
                    ? _c("span", { staticClass: "situacao-inativo" }, [
                        _vm._v("Inativo"),
                      ])
                    : _vm._e(),
                  item.situacao === "retired"
                    ? _c("span", { staticClass: "situacao-afastado" }, [
                        _vm._v("Afastado"),
                      ])
                    : _vm._e(),
                  item.situacao === "vacation"
                    ? _c("span", { staticClass: "situacao-ferias" }, [
                        _vm._v("Afastado"),
                      ])
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }